<template>
  <div>
    <div v-if="info.flag == 2" class="upload-btn" v-loading="loading">
      <i class="el-icon-upload"></i>
      <input type="file" class="input-file" @change="uploadFileFun($event)">
    </div>
    <div v-if="type.length > 0 && info.flag == 2" style="color:#fca40a">{{'只支持' + type + '的文件扩展名'}}</div>
    <div class="file-list">
      <ul>
        <li class="clearfix" v-for="(item, index) of list" :key="index" style="padding-left: 15px;padding-right: 20%;">
          <!-- <span class="iconfont" style="">&#xe655;</span> -->
          <span class="file-name">{{item.oldName}}</span>
          <i class="icon el-icon-download" @click="download(index)"></i>
          <i v-if="info.flag == 2" class="icon el-icon-delete" @click="del(index)"></i>
        </li>
      </ul>
      <div v-if="list.length < 1 && info.flag == 1">无</div>
    </div>
  </div>
</template>
<script>
import { ServicesUploadFile, ServicesDeleteFile, ServicesDownfu } from '@/api/http'
export default {
  name: 'UploadFile',
  data () {
    return {
      limit: 5, // 最多几张，默认5张
      maxSize: 3, // 每个图片最大M,默认3M
      type: [], // 文件类型
      list: [],
      loading: false
    }
  },
  props: ['info'],
  methods: {
    download (index) {
      ServicesDownfu({ path: this.list[index].path }).then(res => {
        const type = res.headers['content-type'].split(';')[0]
        const _blob = new Blob([res.data], { type: type })
        const blob = window.URL.createObjectURL(_blob)
        const link = document.createElement('a')
        const temp = res.headers['content-disposition'].split(';')[1].split('filename=')[1]
        var fileName = decodeURIComponent(temp)
        link.style.display = 'none'
        link.href = blob
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    del (index) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const p = this.list[index].path
        const m = this.list[index].oldName
        ServicesDeleteFile({ path: p + '/' + m, casecode: false }).then(resp => {
        // ServicesDeleteFile({path: this.list[index].path + '/' + this.list[index].oldName, casecode: false}).then(resp => {
          if (resp.code === '000') {
            this.$message({ message: '操作成功', type: 'success' })
            this.list.splice(index, 1)
            this.returnData()
          } else {
            this.$message({ message: resp.msg, type: 'error' })
          }
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    uploadFileFun () {
      if (this.limit <= this.list.length) {
        this.$message.error(`最多只能上传${this.limit}个文件`)
      } else {
        this.loading = true
        const file = event.target.files[0]
        const fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
        if (file) {
          if (file.size > this.maxSize * 1024 * 1024) {
            this.loading = false
            this.$message.error(`文件不能超过${this.maxSize}M！`)
          } else if (this.type.length > 0 && this.type.indexOf(fileType) === -1) {
            this.loading = false
            this.$message.error('文件类型有误！')
          } else {
            const formdata = new FormData()
            formdata.append('file', event.target.files[0])
            ServicesUploadFile(formdata).then(resp => {
              if (resp.code === '000') {
                const fileData = resp.result.filesMap.file[0]
                const obj = {
                  id: fileData.id,
                  name: fileData.fileName,
                  oldName: fileData.remark,
                  path: fileData.path
                }
                this.list.push(obj)
                this.returnData()
                this.loading = false
              } else {
                this.loading = false
                this.$message.error(resp.msg)
              }
            })
          }
        } else {
          this.loading = false
        }
        event.target.value = ''
      }
    },
    returnData () {
      this.$emit('UploadFileFun', this.list)
    }
  },
  created () {

  },
  mounted () {
    const info = this.$props.info
    if (info.maxLength) {
      this.limit = info.maxLength
    }
    if (info.maxSize) {
      this.maxSize = info.maxSize
    }
    if (info.type.length > 0) {
      this.type = info.type || []
    }
    if (info.fileArr) {
      this.list = info.fileArr
    }
  }
}
</script>
<style lang="less" scoped>
.upload-btn {
  width: 360px;
  height: 150px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  text-align: center;
  font-size: 45px;
  color: #d9d9d9;
  line-height: 134px;
  position: relative;
  .input-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
  }
}
.upload-btn:hover {
  border-color: #409eff;
  color: #409eff;
}
.file-list {
  margin-top: 10px;
  width: 100%;
  ul {
    li {
      // width: 70%;
      height: 40px;
      position: relative;
      .icon {
        display: block;
        width: 30px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        cursor: pointer;
        float: right;
        margin-right: 15px;
      }
      .file-name {
        // float: left;
        // display: block;
        height: 100%;
        line-height: 40px;
        font-size: 16px;
        // color: #606266;
      }
    }
    li:hover {
      color: #409eff;
      background-color: #f5f7fa;
    }
  }
}
</style>
