<style lang="less" scoped>

</style>

<template>
  <div class="content-box" v-loading="loading">
    <div class="box-shadow tabel-margin">
      <div style="width: 980px;margin: 50px auto 0;">
        <el-form ref="info" :model="newsPo" label-width="100px" :label-position="'right'">
          <el-form-item label="标题:" :required="true">
            <el-input v-model="newsPo.title" placeholder="请输入标题"></el-input>
          </el-form-item>
          <el-form-item label="所属栏目:" :required="true">
            <el-select v-model="newsPo.cmsType" placeholder="选择栏目" clearable style="height:32px;width: 100%;">
              <el-option
                v-for="(val, ind) in newsList"
                :key="ind"
                :label="val.name"
                :value="val.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作者:">
            <el-input v-model="newsPo.author" placeholder="请输入作者"></el-input>
          </el-form-item>
          <el-form-item label="来源:">
            <el-input v-model="newsPo.source" placeholder="请输入来源"></el-input>
          </el-form-item>
          <el-form-item label="关键字:" :required="true">
            <el-input v-model="newsPo.keyword" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item label="是否外链:" :required="true">
            <el-radio-group v-model="link" style="margin-left: 15px;" @change="radioChange">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="link == 1">
            <el-form-item label="摘要:">
              <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 10}" placeholder="请输入摘要 100字以内" v-model="newsPo.introtext"></el-input>
            </el-form-item>
            <el-form-item label="题图:">
              <My-Uploadimg v-if="newsPo.id" ref="UploadimgAdd" :callbackShow="newsPo.image" :limit="1" @UploadImgFun="UploadImgFun"></My-Uploadimg>
              <My-Uploadimg v-else ref="UploadimgAdd" :limit="1" @UploadImgFun="UploadImgFun"></My-Uploadimg>
            </el-form-item>
            <el-form-item label="正文:" :required="true">
              <div v-if="ueditorShow" style="position: relative;line-height: 20px;">
                <vue-ueditor-wrap v-model="newsPo.content" :config="myConfig"></vue-ueditor-wrap>
              </div>
            </el-form-item>
            <el-form-item label="附件:">
              <upload-file ref="uploadfile" :info="uploadFileConfig" @UploadFileFun="UploadFileFun"></upload-file>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="链接地址:" :required="true">
              <el-input v-model="newsPo.url" placeholder="请输入链接地址"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="btns" style="padding: 30px 0 100px 0;text-align: center;">
          <div class="btn-jing" @click.prevent="submit(1)">保存草稿</div>
          <div class="btn-search" style="margin-left: 15px;" @click.prevent="submit(2)" >直接发布</div>
          <div class="btn-cancel" style="margin-left: 15px;" @click.prevent="goBack">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/utils'
import MyUploadimg from '@/components/uploadimg/Uploadimg'
import UploadFile from '@/components/uploadfile/UploadFile'
import VueUeditorWrap from 'vue-ueditor-wrap'
import { GetColumnList, newsAdd, GetNewsDetail, newsUpdate } from '@/api/http'

export default {
  name: 'ArticleAdd',
  data () {
    return {
      ueditorShow: true,
      myConfig: {
        UEDITOR_HOME_URL: '/ueditor/',
        // 初始容器高度
        initialFrameHeight: 400
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
      },
      EditoritemText: '',
      isClear: false,
      loading: false,
      newsList: '',
      link: 1, // 是否外链
      newsPo: {
        id: '',
        title: '',
        author: '',
        source: '',
        cmsType: '',
        keyword: '',
        introtext: '',
        image: '',
        status: 1, // 1未发布  2已发布
        url: '',
        content: ''
      },
      uploadFileConfig: {
        flag: 2, // 1看   2正常
        maxLength: 2,
        maxSize: 5,
        type: ['pdf', 'docx', 'doc', 'word', 'excel', 'zip'], // 文件类型有就传数组
        // fileArr: [{oldName: '文件', url: 'group2/M00/00/00/rBoFVl8ig9KATAr1AAAfZxLl1pE334.png'}, {oldName: 'group2/M00/00/00/rBoFVl8ig9KATAr1AAAfZxLl1pE334.png', url: 'group2/M00/00/01/rBoFVl8ilFOAUO-MAAABbv4OFUw183.png'}]
        fileArr: []
      },
      fillPathArr: ''
    }
  },
  components: {
    MyUploadimg,
    UploadFile,
    VueUeditorWrap
  },
  computed: {},
  watch: {
    'newsPo.content': function (n, o) {
      this.EditoritemText = util.getPlainTxt(this.newsPo.content)
    }
  },
  methods: {
    // 提交
    submit (sta) {
      this.newsPo.status = sta
      const result = this.verify()
      if (result.status !== '000') {
        this.$message({ message: result.msg, type: 'error' })
        this.loading = false
      } else {
        if (this.newsPo.id) {
          // 修改
          newsUpdate(result.data).then(resp => {
            // this.loading = false
            if (resp.code === '000') {
              this.$message({ message: '提交成功', type: 'success' })
              this.goBackOut()
            } else {
              this.$message({ message: resp.msg, type: 'error' })
            }
          })
        } else {
          // 新增
          newsAdd(result.data).then(resp => {
            // this.loading = false
            if (resp.code === '000') {
              this.$message({ message: '提交成功', type: 'success' })
              this.goBackOut()
            } else {
              this.$message({ message: resp.msg, type: 'error' })
            }
          })
        }
      }
    },
    UploadImgFun (e) {
      this.newsPo.image = e
    },
    // 文件上传函数
    UploadFileFun (e) {
      const pathArr = []
      e.forEach((val, ind) => {
        pathArr.push(val.path)
      })
      this.fillPathArr = pathArr.join(',')
    },
    // 取消 返回上衣页面
    goBack () {
      this.$router.go(-1)
    },
    goBackOut () {
      const that = this
      setTimeout(() => {
        that.$router.go(-1)
      }, 1500)
    },
    // 获取详情
    initialData (id) {
      this.loading = true
      GetNewsDetail({ id: id }).then((res) => {
        this.loading = false
        if (res.code === '000') {
          const data = res.result
          this.newsPo = {
            id: data.id,
            title: data.title,
            author: data.author,
            source: data.source,
            cmsType: data.cmsType,
            keyword: data.keyword,
            introtext: data.introtext,
            image: data.image,
            status: data.status,
            url: data.url,
            content: data.content
          }
          this.ueditorShow = true
          this.EditoritemText = data.contentPure
          if (data.attFiles) {
            this.fillPathArr = data.attFiles
            data.attFileList.forEach((val, ind) => {
              const obj = {
                oldName: val.remark,
                path: val.path
              }
              this.uploadFileConfig.fileArr.push(obj)
            })
          }
          if (res.result.url) {
            this.link = 2
          }
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 监听是否外链
    radioChange (e) {
      if (e === 1) {
        // 没有
        this.newsPo.introtext = ''
        this.newsPo.image = ''
        this.newsPo.status = 1
        this.data.content = ''
        this.EditoritemText = ''
      } else {
        // 有
        this.newsPo.url = ''
      }
    },
    // 获取目录树
    getAllMenu () {
      this.treeLoading = true
      GetColumnList({}).then(resp => {
        this.treeLoading = false
        if (resp.code === '000') {
          const arr = []
          resp.result.forEach((val, ind) => {
            if (val.type === '1' && !val.url) {
              arr.push({ id: val.id, name: val.name })
              if (val.children) {
                val.children.forEach((vall, indd) => {
                  if (vall.type === '1') {
                    arr.push({ id: vall.id, name: '└ ' + vall.name })
                    if (vall.children) {
                      vall.children.forEach((valll, inddd) => {
                        if (valll.type === '1') {
                          console.log(valll)
                          arr.push({ id: valll.id, name: '└ L ' + valll.name })
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          this.newsList = arr
        }
      })
    },
    // 验证
    verify () {
      const obj = {
        status: '000',
        data: ''
      }
      const errArr = []
      if (!this.newsPo.title) {
        errArr.push('标题')
      }
      if (!this.newsPo.cmsType) {
        errArr.push('所属栏目')
      }
      if (!this.newsPo.keyword) {
        errArr.push('关键字')
      }
      if (this.link === 1) {
        if (!this.newsPo.content) {
          errArr.push('正文')
        } else {
          this.newsPo.contentPure = this.EditoritemText
        }
      } else {
        // 外链
        if (!this.newsPo.url) {
          errArr.push('链接地址')
        }
      }
      this.newsPo.attFiles = this.fillPathArr
      if (errArr.length > 0) {
        return this.verifyErr(errArr)
      }
      obj.data = this.newsPo
      return obj
    },
    verifyErr (arr) {
      let text = ''
      arr.forEach((val, ind) => {
        text += val + ','
      })
      text = text.substr(0, text.length - 1)
      const obj = {
        status: 'err',
        msg: text + ' 信息有误'
      }
      return obj
    }
  },
  created () {
    const parameter = this.$route.query
    if (parameter.id) {
      this.initialData(parameter.id)
      this.ueditorShow = false
    }
    this.getAllMenu()
  },
  mounted () {}
}
</script>
