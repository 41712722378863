<template>
  <div>
    <el-upload
      :class="{ hide: hideUploadEdit }"
      action="#"
      :accept="accept"
      :limit="limit"
      :file-list="fileList"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :http-request="uploadImgUp">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :append-to-body='true'>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { Uploadimg } from '@/api/http'

// 文件上传个数 limit                 type = Number
// 图片回显 callbackShow              type = String
// 接受组件上传后回调 UploadImgFun     type = Function
export default {
  name: 'MyUploadimg',
  props: ['limit', 'callbackShow'],
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      hideUploadEdit: false,
      fileList: [],
      imgArr: [],
      accept: 'image/jpeg,image/png,image/jpg'
    }
  },
  watch: {
    '$props.callbackShow': function (newValue, oldValue) {
      if (newValue) {
        const arr = this.callbackShow.split(',')
        const newArr = arr.map(item => ({ url: this.$imgBaseUrl + item, path: item }))
        this.imgArr = newArr
        this.fileList = newArr
        this.hideUploadEdit = this.fileList.length >= this.limit
      }
    },
    // 监听图片上传 使用this.$emit导出图片地址
    imgArr (newValue, oldValue) {
      const arr = []
      for (let i = 0; i < newValue.length; i++) {
        arr.push(newValue[i].path)
      }
      this.$emit('UploadImgFun', arr.join(','))
    }
  },
  created () {
    // 通过接受 this.callbackShow 判断是否需要回显
    if (this.callbackShow) {
      const arr = this.callbackShow.split(',')
      const newArr = arr.map(item => ({ url: this.$imgBaseUrl + item, path: item }))
      this.imgArr = newArr
      this.fileList = newArr
      this.hideUploadEdit = this.fileList.length >= this.limit
    }
  },
  methods: {
    // 弹窗关闭清空显示
    // callbackRemove() {
    //   this.imgArr = []
    //   this.fileList = []
    //   this.hideUploadEdit = this.fileList.length >= this.limit
    // },
    // 显示
    handlePictureCardPreview(file) {
      this.dialogVisible = true
      this.dialogImageUrl = file.url
    },
    // 本地校验
    handleChange (file, fileList) {
      this.fileList = fileList
      this.hideUploadEdit = fileList.length >= this.limit
    },
    // 删除文件
    handleRemove(file, fileList) {
      for (let i = 0; i < this.imgArr.length; i++) {
        if (file.path == this.imgArr[i].path) {
          this.imgArr.splice(i, 1)
        }
      }
      this.fileList = fileList
      this.hideUploadEdit = fileList.length >= this.limit
    },
    // 上传文件
    uploadImgUp (f) {
      const formdata = new FormData()
      formdata.append('file', f.file)
      Uploadimg(formdata).then(res => {
        if (res.code === '000') {
          this.fileList[this.fileList.length - 1].path = res.result.filesMap.file[0].path
          this.imgArr.push(res.result.filesMap.file[0])
        } else {
          this.$message({ message: res.msg, type: 'error' })
          this.fileList.splice(this.fileList.length - 1, 1)
          this.hideUploadEdit = this.fileList.length >= this.limit
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less">
.hide .el-upload--picture-card {
  display: none;
}
</style>
